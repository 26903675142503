import React, { useState, useEffect } from "react";
import AdminFooter from "./components/admin_footer";
import AdminSidebar from "../pages/components/admin_sidebar";
import AdminHeader from "./components/admin_header";
import { useLocation, useNavigate } from "react-router-dom";
import useAxiosPrivate from "../middlewares/hooks/useAxiosPrivate";
import { useForm, Controller } from "react-hook-form";
import { useParams } from "react-router-dom";
import ProgressBar from "@badrap/bar-of-progress";
import Pagination from "./pagination";
import toast, { Toaster } from "react-hot-toast";
import fileDownload from "js-file-download";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";

const SmsReport = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [isGenerating, setIsGenerating] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false); // New state for tracking download status
  const [progress, setProgress] = useState(null);
  const [generateButtonClicked, setGenerateButtonClicked] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [startDate, setStartDate] = useState([]);
  const [endDate, setEndDate] = useState([]);
  const [smsData, setSmsData] = useState([]);
  const [saccoName, setSaccoName] = useState([]);
  const [smsCount, setSmsCount] = useState(0);
  const [smsAmount, setSmsAmount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [saccosName, setSaccosName] = useState("");
  const itemsPerPage = 10;

  const  fetchSmsData = async () => {
    if (generateButtonClicked) {
      if (progress) {
        progress.start();
      }
      try {
        const requestData = {
          start_date: startDate,
          end_date: endDate,
        };
        const controller = new AbortController();
      const response = await axiosPrivate.post(
        `/sms_report/${params.id}/page/${currentPage}`,
        requestData,
        { signal: controller.signal }
      );
      // console.log(response);
      setSmsData(response.data.data.sms);
      setSmsCount(response.data.data.sms_count);
      setSaccoName(response.data.data.sacco);
      setSmsAmount(response.data.data.total_amount);
      if (progress) {
        progress.finish();
      }
      } catch (error) {
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        } else {
          console.error("Error fetching SMS :", error);
          toast.error("No SMS info found", {
            style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
          });
        }
        if (progress) {
          progress.finish();
        }
      }
    }
  };
  const controller = new AbortController();
  const getSaccosName = async () => {
    try {
      const response = await axiosPrivate.get(`/saccos/${params.id}`, {
        signal: controller.signal,
      });
      setSaccosName(response.data.data.sacco.sacco_name);
    } catch (error) {
      console.error("Error fetching saccos:", error);
      if (error?.response?.status === 401) {
        navigate("/", {
          state: { from: location },
          replace: true,
        });
      }
    }
  };

  useEffect(() => {
    getSaccosName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    fetchSmsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPage,
    startDate,
    endDate,
    generateButtonClicked
  ]);

  useEffect(() => {
    const pages = Math.ceil(smsCount / itemsPerPage);
    setTotalPages(pages);
  }, [smsCount, itemsPerPage]);

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const onSubmit = async (data) => {
    setGenerateButtonClicked(true);
    const formattedStartDate = format(data.start_date, "yyyy-MM-dd HH:mm:ss");
    const formattedEndDate = format(data.end_date, "yyyy-MM-dd HH:mm:ss");
    setStartDate(formattedStartDate);
    setEndDate(formattedEndDate);
    setIsGenerating(true); // Set isGenerating to true when the form is submitted
    setProgress(new ProgressBar({ size: 5, color: "#e91e63" }));
    await fetchSmsData();
    setIsGenerating(false); // Set isGenerating back to false when the asynchronous operation is completed
    setProgress(null);
  };

  const download = async () => {
    try {
      setIsDownloading(true); // Set isDownloading to true when download starts
      if (progress) {
        progress.start();
      }
      const requestData = {
        start_date: startDate,
        end_date: endDate,
      };
      // console.log(requestData);
      const response = await axiosPrivate.post(
        `/exports/sms_report/${params.id}`,
        requestData,
        { responseType: "blob" }
      );
      fileDownload(response.data, `${saccoName}_SMS_Report.pdf`);
    } catch (error) {
      console.error("Error downloading SMS report:", error);
      if (!error?.response) {
        console.log("No server response");
      } else {
        console.log(error?.response);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
      }
    } finally {
      setIsDownloading(false);
      if (progress) {
        progress.finish();
      }
    }
  };

  return (
    <>
     <Toaster />
      <AdminHeader />
      <div className="d-flex align-items-stretch">
        <AdminSidebar />
        <div className="page-holder w-100 d-flex flex-wrap">
          <div className="container-fluid px-xl-5">
            <section className="py-5 mt-3">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card mb-5 mb-lg-0">
                    <div className="card-header bg-dark text-white">
                      <h2 className="h6 mb-0 text-uppercase text-center">
                       {saccosName}{" "} SMS Report
                      </h2>
                    </div>
                    <div className="card-body mt-3 mb-3">
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <form
                            className="row needs-validation"
                            id="generalLegerForm"
                            onSubmit={handleSubmit(onSubmit)}
                          >
                            <div className="form-group col-md-3">
                              <label className="form-label">
                                Select Start Date
                              </label>
                              <Controller
                                name="start_date"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                  <DatePicker
                                    className="form-control border-0 shadow form-control-md input-text"
                                    selected={field.value}
                                    onChange={(date) => field.onChange(date)}
                                    showTimeSelect
                                    timeFormat="HH:mm:ss"
                                    dateFormat="yyyy-MM-dd HH:mm:ss"
                                    timeIntervals={1}
                                    timeCaption="time"
                                  />
                                )}
                              />
                              {errors?.start_date?.type === "required" && (
                                <p className="text-small m-0 text-danger help-block">
                                  This field is required
                                </p>
                              )}
                            </div>
                            <div className="form-group col-md-3">
                              <label className="form-label">
                                Select End Date
                              </label>
                              <Controller
                                name="end_date"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                  <DatePicker
                                    className="form-control border-0 shadow form-control-md input-text"
                                    selected={field.value}
                                    onChange={(date) => field.onChange(date)}
                                    showTimeSelect
                                    timeFormat="HH:mm:ss"
                                    dateFormat="yyyy-MM-dd HH:mm:ss"
                                    timeIntervals={1}
                                    timeCaption="time"
                                  />
                                )}
                              />
                              {errors?.end_date?.type === "required" && (
                                <p className="text-small m-0 text-danger help-block">
                                  This field is required
                                </p>
                              )}
                            </div>
                            <div className="form-group col-md-3 pt-3 mt-3">
                              <button
                                type="submit"
                                className="btn btn-primary text-center rounded-0"
                                disabled={isGenerating || isDownloading}
                              >
                                {!isGenerating ? "Generate" : "Please Wait ..."}
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div className="mt-3">
                        <div className="d-flex justify-content-between mb-3">
                          <div>
                            <h5>Sacco Name: {saccoName} </h5>
                          </div>
                          <div>
                            <span className="mr-3">
                              Start Date: {startDate}
                            </span>
                            <span>End Date: {endDate}</span>
                          </div>
                        </div>
                        <table className="table table-hover table-bordered text-medium-small">
                          <thead>
                            <tr className="bg-dark text-white text-medium-small">
                              <th>Contact</th>
                              <th>Message</th>
                              <th>Length</th>
                              <th>Status</th>
                              <th>Timestamp</th>
                            </tr>
                          </thead>
                          <tbody className="text-medium-small">
                            {smsData.map((sms) => (
                              <tr key={sms.id}>
                                <td>{sms.contact}</td>
                                <td>{sms.message}</td>
                                <td>{sms.length}</td>
                                <td>{sms.pay_status}</td>
                                <td>{sms.timestamp}</td>
                              </tr>
                            ))}
                            <tr className="bg-dark text-white">
                              <th >Sms Count</th>
                              <th>{smsCount?.toLocaleString()}</th>
                              <th colSpan="2">Total Amount</th>
                              <th>{smsAmount}</th>
                            </tr>
                          </tbody>
                        </table>
                        <Pagination
                          currentPage={currentPage}
                          totalPages={totalPages}
                          onPageChange={onPageChange}
                        />
                        <div className="d-flex align-items-center">
                          <div className="ml-auto">
                            <button
                              className="btn btn-primary"
                              onClick={download}
                              disabled={isGenerating || isDownloading}
                            >
                              {isDownloading
                          ? "Downloading please wait..."
                          : "Download Report"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="card-footer">
                      <button
                        className="btn btn-primary"
                        onClick={download}
                        disabled={isGenerating || isDownloading}
                      >
                        {isDownloading
                          ? "Downloading please wait..."
                          : "Download Report"}
                      </button>
                    </div> */}
                  </div>
                </div>
              </div>
            </section>
          </div>
          <AdminFooter />
        </div>
      </div>
    </>
  );
};

export default SmsReport;
